'use client';

import { usePathname } from 'next/navigation';

import { NavItemLink } from '@/components/NavItemLink';

import { type NavItem, NavItems } from '@/utils/navItems';
import { Box, List, ListItem, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';

const getActiveIndex = (
   activeIndex: number | null,
   index: number,
   pathname: string,
   item: NavItem,
) => {
   if (activeIndex !== null) {
      return index === activeIndex;
   }

   return item.link !== '/' ? pathname.includes(item.link) : pathname === '/';
};

export default function MainSidebar() {
   const pathname = usePathname();
   const [activeIndex, setActiveIndex] = useState<number | null>(null);

   const borderRadius = useBreakpointValue({
      base: '0px',
      lg: '50px',
   });

   return (
      <List
         as={motion.ul}
         display="flex"
         role="navigation"
         flexDir={{ base: 'row', lg: 'column' }}
         justifyContent={{
            base: 'space-between',
            md: 'center',
            '2xl': 'flex-start',
         }}
         gap={{ base: 0, lg: 2 }}
         flex={{ base: 1, lg: 1 }}
      >
         {NavItems.map((item, index) => {
            const isActive = getActiveIndex(activeIndex, index, pathname, item);

            return (
               <ListItem
                  key={item.label}
                  as={motion.li}
                  w={{ base: '20%', lg: '100%' }}
                  style={{ position: 'relative' }}
                  onClick={() => setActiveIndex(index)}
               >
                  <NavItemLink isActive={isActive} {...item} />
                  <Box hideBelow="md">
                     {isActive ? (
                        <motion.span
                           style={{
                              position: 'absolute',
                              inset: 0,
                              background: '#1F545E',
                              zIndex: -1,
                              color: 'white',
                              borderRadius,
                           }}
                           layoutId="shadow"
                           transition={{
                              layout: {
                                 duration: 0.3,
                                 type: 'spring',
                                 stiffness: 1000,
                                 damping: 100,
                              },
                           }}
                        />
                     ) : null}
                  </Box>
               </ListItem>
            );
         })}
      </List>
   );
}
